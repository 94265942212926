import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import sanityClient from '../../sanity';
import './Ongoing.css';

const JobCard = ({ category, title, description, imageUrl, slug }) => {
  const navigate = useNavigate();

  const handleReadMore = () => {
    if (slug) {
      navigate(`/projects/${slug}`);
    }
  };

  return (
    <div className="OngoingCallsProject-card">
      <div className="OngoingCallsProject-card-image-container">
        {imageUrl ? (
          <img src={imageUrl} alt={title} className="OngoingCallsProject-card-image" />
        ) : (
          <div className="OngoingCallsProject-card-image-placeholder">No Image Available</div>
        )}
      </div>
      <div className="OngoingCallsProject-card-content">
        <p className="OngoingCallsProject-card-category">{category}</p>
        <h3 className="OngoingCallsProject-card-title">{title}</h3>
        <div className="OngoingCallsProject-card-description-container">
          <p className="OngoingCallsProject-card-description">{description || 'No description available.'}</p>
        </div>
        <button className="OngoingCallsProject-card-button" onClick={handleReadMore} disabled={!slug}>
          Read More
        </button>
      </div>
    </div>
  );
};

const OngoingCallsProject = () => {
  const [ongoingCalls, setOngoingCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "projectsPost" && category == "ongoing"]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          metaDescription
        }`
      )
      .then((data) => {
        if (data.length === 0) {
          setError('No ongoing calls found.');
        }
        setOngoingCalls(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Sanity fetch error:', err);
        setError('Failed to load ongoing calls.');
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading ongoing calls...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="OngoingCallsProject">
      <div className="OngoingCallsProject-header">
        <h2 className="OngoingCallsProject-subheading">JOIN THE FUTURE : ONGOING CALLS NOW AVAILABLE</h2>
        <h1 className="OngoingCallsProject-heading">INVESTMENT OPPORTUNITIES AWAIT</h1>
        <div className="OngoingCallsProject-heading-underline"></div>
      </div>
      {ongoingCalls.length > 0 ? (
        <div className="OngoingCallsProject-grid">
          {ongoingCalls.map((call) => (
            <JobCard
              key={call.slug?.current || Math.random()}
              title={call.title || 'Untitled Project'}
              category="Ongoing"
              description={call.metaDescription}
              imageUrl={call.mainImage?.asset?.url}
              slug={call.slug?.current}
            />
          ))}
        </div>
      ) : (
        <div className="no-ongoing-calls">
          <p>No ongoing calls available at the moment. Stay tuned for future opportunities!</p>
        </div>
      )}
    </div>
  );
};

export default OngoingCallsProject;