import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import sanityClient from '../../sanity';
import './News.css';

const NewsCard = ({ imageUrl, metaTitle, metaDescription, slug }) => (
  <div className="aic-news-card">
    <div className="aic-news-card-image-container">
      {imageUrl ? (
        <img src={imageUrl} alt={metaTitle} className="aic-news-card-image" />
      ) : (
        <div className="aic-news-card-image-placeholder">No Image Available</div>
      )}
    </div>
    <div className="aic-news-card-content">
      <p className="aic-news-category">News</p>
      <h3 className="aic-news-card-title">{metaTitle}</h3>
      <p className="aic-news-card-description">{metaDescription}</p>
      <Link to={`/news/${slug}`} className="aic-news-read-more">
        Read More
      </Link>
    </div>
  </div>
);

const News = () => {
  const [newsPosts, setNewsPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "newsPost" && category == "news"] | order(publishedAt desc)[0...4]{
          metaTitle,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          metaDescription
        }`
      )
      .then((data) => {
        setNewsPosts(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching news posts:', err);
        setError('Failed to load news posts.');
        setLoading(false);
      });
  }, []);

  if (loading) return <div className="aic-news-loading">Loading news...</div>;
  if (error) return <div className="aic-news-error">{error}</div>;

  return (
    <div className="aic-news-section" style={{backgroundImage: `url('news.jpg')`}}>
      <div className="aic-news-overlay"></div>
      <div className="aic-news-content">
        <div className="aic-news-header">
          <h2 className="aic-news-heading">NEWS FROM ALBANIA INVESTMENT CORPORATION</h2>
          <p className="aic-news-subheading">Making Headlines in the Investment World</p>
          <div className="aic-news-heading-underline"></div>
        </div>

        {newsPosts.length > 0 ? (
          <div className="aic-news-grid">
            {newsPosts.map((post) => (
              <NewsCard
                key={post.slug.current}
                imageUrl={post.mainImage?.asset?.url}
                metaTitle={post.metaTitle}
                metaDescription={post.metaDescription}
                slug={post.slug.current}
              />
            ))}
          </div>
        ) : (
          <div className="aic-news-empty-message">
            <p>No news articles available at the moment. Check back soon for updates!</p>
          </div>
        )}

        <Link to="/news" className="aic-news-see-more-button">SEE MORE</Link>
      </div>
    </div>
  );
};

export default News;