import React, { useState, useEffect, useRef } from 'react';
import './Logo.css';

const FullWidthLogoCarousel = () => {
  const allLogos = [
    '../verona.png',
    '../veronafiere.png',
    '../messe.png',
    '../risi.png',
    '../add.png',
    '../ita.png',
    '../ifema.png',
    '../dyrrah.png',
  ];

  const [visibleLogos, setVisibleLogos] = useState(5);
  const totalLogos = allLogos.length;
  const [currentIndex, setCurrentIndex] = useState(visibleLogos);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const carouselWrapperRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setVisibleLogos(1);
      } else if (window.innerWidth <= 768) {
        setVisibleLogos(2);
      } else if (window.innerWidth <= 992) {
        setVisibleLogos(3);
      } else if (window.innerWidth <= 1200) {
        setVisibleLogos(4);
      } else {
        setVisibleLogos(5);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (currentIndex >= totalLogos + visibleLogos) {
        setIsTransitioning(false);
        setCurrentIndex(visibleLogos);
      }
    };

    const carouselElement = carouselWrapperRef.current;
    if (carouselElement) {
      carouselElement.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [currentIndex, totalLogos, visibleLogos]);

  useEffect(() => {
    if (!isTransitioning) {
      setTimeout(() => {
        setIsTransitioning(true);
      }, 50);
    }
  }, [isTransitioning]);

  const carouselStyle = {
    transform: `translateX(-${currentIndex * (100 / visibleLogos)}%)`,
    transition: isTransitioning ? 'transform 0.3s ease-in-out' : 'none',
  };

  return (
    <div className="carousel-container">
      <div className="content-wrapper">
        <h2 className="subtitle">SYNERGIZE AND SUCCEED</h2>
        <div className="title-container">
          <div className="red-line"></div>
          <h1 className="title">COLLABORATIVE PARTNERSHIPS WITH AIC</h1>
        </div>
        <div className="carousel">
          <div
            ref={carouselWrapperRef}
            className="carousel-wrapper"
            style={carouselStyle}
          >
            {allLogos.slice(-visibleLogos).map((logo, index) => (
              <div key={`start-${index}`} className="carousel-item">
                <img src={logo} alt={`Logo ${index + 1}`} className="logo" />
              </div>
            ))}

            {allLogos.map((logo, index) => (
              <div key={index} className="carousel-item">
                <img src={logo} alt={`Logo ${index + 1}`} className="logo" />
              </div>
            ))}

            {allLogos.slice(0, visibleLogos).map((logo, index) => (
              <div key={`end-${index}`} className="carousel-item">
                <img src={logo} alt={`Logo ${index + 1}`} className="logo" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullWidthLogoCarousel;