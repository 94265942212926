import React from 'react';
import './Pin.css';

const Pin = () => {
  const pinBase64 = 'pin.png'; // Replace with your base64 string
  const leftImageBase64 = 'Asset 3.png'; // Replace with your left image base64 string
  const rightImageBase64 = 'Asset 3.png'; // Replace with your right image base64 string

  return (
    <div className="pin-container">
      <div className="pin-header">
        <img src={leftImageBase64} alt="Left side view" className="pin-side-image pin-left-image" />
        <div className="pin-header-content">
        <h1>RELATIONSHIP WITH OTHER PUBLIC SECTOR INSTITUTIONS</h1>
<p>
  The Albanian Investment Corporation fosters strong partnerships with key public sector institutions to ensure alignment with national development goals. By collaborating with government bodies, local municipalities, and regulatory agencies, we streamline the investment process and create a conducive environment for both domestic and international investors. These relationships enable us to work efficiently within Albania's regulatory framework, ensuring that our projects meet the highest standards of transparency and sustainability. Together, we are building a solid foundation for long-term economic growth and prosperity.
</p>

        </div>
        <img src={rightImageBase64} alt="Right side view" className="pin-side-image pin-right-image" />
      </div>
      <div className="pin-card-container">
        <div className="pin-card">
          <div className="pin-icon">
            <img src={pinBase64} alt="Pin icon" />
          </div>
          <div className="pin-card-content">
            <h3>RELATIONSHIP WITH LINE MINISTRIES</h3>
            <p>
              Ministries are sites where public policy is formulated. The AIC law is clear that it will take a decision of the Council of
              Ministers to authorize AIC to work on a project where public assets are at stake. In this way, AIC is a tool, an agent, at
              the disposal of line ministries, enabling them to employ AIC in order to actualize a project that is part of their vision, if
              they so choose.
            </p>
          </div>
        </div>
        <div className="pin-card">
          <div className="pin-icon">
            <img src={pinBase64} alt="Pin icon" />
          </div>
          <div className="pin-card-content">
            <h3>RELATIONSHIP WITH AIDA</h3>
            <p>
              Ministries are sites where public policy is formulated. The AIC law is clear that it will take a decision of the Council of
              Ministers to authorize AIC to work on a project where public assets are at stake. In this way, AIC is a tool, an agent, at
              the disposal of line ministries, enabling them to employ AIC in order to actualize a project that is part of their vision, if
              they so choose.
            </p>
          </div>
        </div>
        <div className="pin-card">
          <div className="pin-icon">
            <img src={pinBase64} alt="Pin icon" />
          </div>
          <div className="pin-card-content">
            <h3>RELATIONSHIP WITH THE ALBANIAN DEVELOPMENT FUND (ADF)</h3>
            <p>
              Ministries are sites where public policy is formulated. The AIC law is clear that it will take a decision of the Council of
              Ministers to authorize AIC to work on a project where public assets are at stake. In this way, AIC is a tool, an agent, at
              the disposal of line ministries, enabling them to employ AIC in order to actualize a project that is part of their vision, if
              they so choose.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pin;
