import React, { useState, useEffect } from 'react';
import sanityClient from '../../sanity'; // Import Sanity client
import './ClosedCalls.css';  // Import CSS for styling

const JobCard = ({ category, title, description, imageUrl, slug }) => (
  <div className="closed-calls-card">
    <div className="closed-calls-card-image-container">
      <img src={imageUrl} alt={title} className="closed-calls-card-image" />
    </div>
    <div className="closed-calls-card-content">
      <p className="closed-calls-card-category">{category}</p>
      <h3 className="closed-calls-card-title">{title}</h3>
      <p className="closed-calls-card-description">{description}</p>
      <button className="closed-calls-card-button">Read More</button>
    </div>
  </div>
);

const ClosedCallsProject = () => {
  const [closedCalls, setClosedCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClosedCalls = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "projectsPost" && category == "closed"]{
            title,
            slug,
            mainImage{
              asset->{
                _id,
                url
              },
              alt
            },
            body,
            metaDescription,
            "excerpt": pt::text(body)[0...200]
          }`
        );
        setClosedCalls(data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching closed calls:", err);
        setError("Failed to load closed calls. Please try again later.");
        setLoading(false);
      }
    };

    fetchClosedCalls();
  }, []);

  if (loading) return <div className="closed-calls-loading">Loading...</div>;
  if (error) return <div className="closed-calls-error">{error}</div>;

  return (
    <div className="closed-calls">
      <div className="closed-calls-header">
        <h2 className="closed-calls-subheading">Explore Our Closed Investment Opportunities</h2>
        <h1 className="closed-calls-heading">These Calls Have Ended</h1>
        <div className="closed-calls-heading-underline"></div>
      </div>
      {closedCalls.length > 0 ? (
        <div className="closed-calls-grid">
          {closedCalls.map((call) => (
            <JobCard
              key={call.slug.current}
              title={call.title}
              category="Closed"
              description={call.metaDescription || call.excerpt}
              imageUrl={call.mainImage?.asset?.url}
              slug={call.slug.current}
            />
          ))}
        </div>
      ) : (
        <div className="no-closed-calls">
          <p>No closed calls available at the moment. Stay tuned for future opportunities!</p>
        </div>
      )}
    </div>
  );
};

export default ClosedCallsProject;