import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import sanityClient from '../../sanity';
import BlockContent from '@sanity/block-content-to-react';
import { ArrowLeft, ArrowRight, ExternalLink } from 'lucide-react';

const NewsDetails = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [nextPost, setNextPost] = useState(null);
  const [prevPost, setPrevPost] = useState(null);
  const [projects, setProjects] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          gallery[]{asset->{_id, url}},
          pdfFiles[]{
            asset->{url},
            customName
          },
          body,
          tags[]->{
            title,
            slug
          },
          category,
          "next": *[_type == "newsPost" && dateTime(_createdAt) > dateTime(^._createdAt)] | order(_createdAt asc) [0]{ title, slug },
          "previous": *[_type == "newsPost" && dateTime(_createdAt) < dateTime(^._createdAt)] | order(_createdAt desc) [0]{ title, slug }
        }`,
        { slug }
      )
      .then((data) => {
        setPost(data[0]);
        setNextPost(data[0]?.next);
        setPrevPost(data[0]?.previous);
        window.scrollTo(0, 0);
      })
      .catch(console.error);

    sanityClient
      .fetch(
        `*[_type == "projectsPost"]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          excerpt
        }`
      )
      .then((data) => setProjects(data))
      .catch(console.error);
  }, [slug]);

  const handleNavigation = (postSlug) => {
    navigate(`/news/${postSlug}`);
    window.scrollTo(0, 0);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === post.gallery.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? post.gallery.length - 1 : prevIndex - 1
    );
  };

  const nextProject = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === projects.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevProject = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === 0 ? projects.length - 1 : prevIndex - 1
    );
  };

  const limitTitleLength = (title, limit = 20) => {
    return title.length > limit ? title.substring(0, limit) + '...' : title;
  };

  if (!post) return <div className="text-center py-10">Loading...</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 flex flex-col lg:flex-row lg:justify-between">
      <div className="flex-1 max-w-3xl bg-white rounded-lg shadow-md p-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-5 pb-2 border-b-2 border-red-600">
          {post.title}
        </h1>
        <div className="text-sm text-gray-600 uppercase tracking-wide mb-5">
          {post.category}
        </div>

        {post.mainImage?.asset?.url && (
          <img
            src={post.mainImage.asset.url}
            alt={post.mainImage.alt || post.title}
            className="w-full h-96 object-cover rounded-lg mb-8"
          />
        )}

        <div className="prose max-w-none mb-8">
          <BlockContent
            blocks={post.body}
            projectId="1ag8k8v1"
            dataset="production"
          />
        </div>

        {post.pdfFiles && post.pdfFiles.length > 0 && (
          <div className="bg-gray-100 border border-gray-300 rounded-lg p-6 mt-8">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Related Documents</h3>
            <div className="space-y-4">
              {post.pdfFiles.map((pdfFile, index) => (
                <div key={index} className="flex flex-col sm:flex-row gap-4 items-center">
                  <div className="bg-red-600 text-white py-2 px-4 rounded-md font-semibold">
                    <span>{pdfFile.customName || `PDF ${index + 1}`}</span>
                  </div>
                  <a 
                    href={pdfFile.asset.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center bg-gray-200 hover:bg-gray-300 text-gray-800 py-2 px-4 rounded-md transition duration-300"
                  >
                    <ExternalLink size={20} className="mr-2" />
                    <span>View PDF</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}

        {post.tags && post.tags.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-8">
            {post.tags.map((tag) => (
              <Link 
                key={tag.slug.current} 
                to={`/tags/${tag.slug.current}`} 
                className="bg-gray-200 text-red-600 hover:bg-red-600 hover:text-white px-3 py-1 rounded-full text-sm transition duration-300"
              >
                {tag.title}
              </Link>
            ))}
          </div>
        )}

        {post.gallery && post.gallery.length > 0 && (
          <div className="mt-10 mb-10">
            <h3 className="text-2xl text-gray-800 mb-5">Gallery</h3>
            <div className="relative h-96 bg-gray-100 rounded-lg overflow-hidden">
              <button 
                onClick={prevImage} 
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-red-600 bg-opacity-70 hover:bg-opacity-90 text-white rounded-full p-2 transition duration-300 z-10"
              >
                <ArrowLeft size={20} />
              </button>
              <div className="w-full h-full flex justify-center items-center">
                <img
                  src={post.gallery[currentImageIndex].asset.url}
                  alt={`Gallery item ${currentImageIndex + 1}`}
                  className="max-w-full max-h-full object-contain"
                />
              </div>
              <button 
                onClick={nextImage} 
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-red-600 bg-opacity-70 hover:bg-opacity-90 text-white rounded-full p-2 transition duration-300 z-10"
              >
                <ArrowRight size={20} />
              </button>
            </div>
            <p className="text-center mt-2 text-gray-600 italic">
              {`Image ${currentImageIndex + 1} of ${post.gallery.length}`}
            </p>
          </div>
        )}

        <div className="flex flex-col sm:flex-row justify-between gap-4 mt-10">
          {prevPost && (
            <button 
              onClick={() => handleNavigation(prevPost.slug.current)} 
              className="flex items-center justify-center sm:justify-start bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-full transition duration-300 transform hover:-translate-y-1"
            >
              <ArrowLeft size={20} className="mr-2" />
              <span>Previous: {limitTitleLength(prevPost.title)}</span>
            </button>
          )}
          {nextPost && (
            <button 
              onClick={() => handleNavigation(nextPost.slug.current)} 
              className="flex items-center justify-center sm:justify-end bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-full transition duration-300 transform hover:-translate-y-1"
            >
              <span>Next: {limitTitleLength(nextPost.title)}</span>
              <ArrowRight size={20} className="ml-2" />
            </button>
          )}
        </div>
      </div>

      <div className="w-full lg:w-80 mt-10 lg:mt-0 lg:ml-8">
        <div className="sticky top-5 bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl text-gray-800 mb-5 pb-2 border-b-2 border-red-600">Our Projects</h2>
          {projects.length > 0 ? (
            <div>
              {projects[currentProjectIndex].mainImage?.asset?.url && (
                <img
                  src={projects[currentProjectIndex].mainImage.asset.url}
                  alt={projects[currentProjectIndex].mainImage.alt || projects[currentProjectIndex].title}
                  className="w-full h-48 object-cover rounded-lg mb-4"
                />
              )}
              <div>
                <h3 className="text-xl text-gray-800 mb-2">{projects[currentProjectIndex].title}</h3>
                <p className="text-sm text-gray-600 mb-4">{projects[currentProjectIndex].excerpt}</p>
                <Link 
                  to={`/projects/${projects[currentProjectIndex].slug.current}`} 
                  className="inline-block bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm font-semibold transition duration-300"
                >
                  View Project
                </Link>
              </div>
              <div className="flex justify-between mt-4">
                <button onClick={prevProject} className="text-red-600 hover:text-red-700 transition duration-300">
                  <ArrowLeft size={20} />
                </button>
                <button onClick={nextProject} className="text-red-600 hover:text-red-700 transition duration-300">
                  <ArrowRight size={20} />
                </button>
              </div>
            </div>
          ) : (
            <p className="text-gray-600">No projects available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsDetails;