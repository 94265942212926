import React from 'react';
import './ImpactSection.css';

const CustomArrowIcon = () => {
  return (
    <svg
      className="impact-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4 4L20 12L4 20V4Z" />
    </svg>
  );
};

const ImpactSection = () => {
  return (
    <section className="impact-section">
      <h2 className="impact-title">The Impact in Economic Development</h2>
      <ul className="impact-list">
        <li className="impact-item">
          <CustomArrowIcon />
          AIC is a beacon institution in Albania’s strategy to foster economic growth. By supporting investment projects that align with state policies, the corporation helps to create a favorable environment for economic development and innovation.
        </li>
        <li className="impact-item">
          <CustomArrowIcon />
          The corporation focuses on optimizing the use of state assets to ensure they contribute effectively to the national economy. This includes transforming underutilized assets into productive investments that can generate economic value.
        </li>
        <li className="impact-item">
          <CustomArrowIcon />
          AIC works to mobilize both public and private capital for investment projects. This approach helps to diversify funding sources and ensures that projects have the necessary financial backing to succeed.
        </li>
        <li className="impact-item">
          <CustomArrowIcon />
          One of AIC’s primary functions is to convert innovative ideas into funded projects. This involves comprehensive project review, structuring, and financing, which helps to bring concepts from the planning stage to implementation.
        </li>
        <li className="impact-item">
          <CustomArrowIcon />
          AIC collaborates closely with the Albanian government to review and structure investment projects. This partnership ensures that projects are aligned with national development goals and are implemented efficiently.
        </li>
      </ul>
    </section>
  );
};

export default ImpactSection;
