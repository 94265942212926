import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

// Static page imports
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import WhyChooseAIC from './Pages/About/WhyChooseAIC';
import ByLaw from './Pages/About/ByLaw'
import Contact from './Pages/Contact/Contact';
import Projects from './Pages/Projects/Projects';
import Ongoing from './Pages/Open Calls/Ongoing';
import ClosedCalls from './Pages/Open Calls/ClosedCalls';
import MeetTheStaff from './Pages/Team/MeetTheStaff';
import Bio from './Pages/Team/Bio';
import InstitutionalStructure from './Pages/Team/InstitutionalStructure'
import AICRelationshipPage from './Pages/Networking/AICRelationshipPage';
import NewsPage from './Pages/News/NewsPage';

// Dynamic page imports
import ProjectDetails from './Pages/Projects/ProjectsDetails';
import NewsDetails from './Pages/News/NewsDetails';
import TagPages from './Pages/News/TagPages';

// Component imports
import MainHeader from './components/MainHeader';
import Footer from './components/Footer';
import Loading from './components/Loading';

import './App.css';
import JoinUs from './Pages/Team/JoinUs';

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    
    const timer = setTimeout(() => {
      setLoading(false);
    }, 650);

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <div className="App">
      {loading && <Loading />}

      {location.pathname !== '/' && <MainHeader />}
      
      <Routes>
        {/* Static Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about-us/vision-and-mission" element={<About />} />
        <Route path="/about-us/why-choose-aic" element={<WhyChooseAIC />} />
        <Route path="/about-us/law-and-regulations" element={<ByLaw />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/team/meet-the-staff" element={<MeetTheStaff />} />
        <Route path="/team/join-us" element={<JoinUs />} />
        <Route path="/team/institutional-structure" element={<InstitutionalStructure />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/open-calls/closed" element={<ClosedCalls/>} />
        <Route path="/open-calls/ongoing" element={<Ongoing />} />
        <Route path="/networking/relationship-with-line-ministers" element={<AICRelationshipPage />} />
        <Route path="/news" element={<NewsPage />} />

        {/* Dynamic Routes */}
        <Route path="/projects/:slug" element={<ProjectDetails />} />
        <Route path="/news/:slug" element={<NewsDetails />} />
        <Route path="/tags/:slug" element={<TagPages />} />
        <Route path="/team/meet-the-staff/:slug" element={<Bio />} />
      </Routes>
      
      <Footer />
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}