import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import sanityClient from '../../sanity';
import './AICRelationshipPage.css';

const AICRelationshipPage = () => {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState(location.pathname);
  const [projects, setProjects] = useState([]);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  const sidebarButtons = [
    { name: 'RELATIONSHIP WITH LINE MINISTRIES', path: '/networking/relationship-with-line-ministers' },
    { name: 'RELATIONSHIP WITH AIDA'},
    { name: 'RELATIONSHIP WITH ADF'},
  ];

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "projectsPost"]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          excerpt
        }`
      )
      .then((data) => setProjects(data))
      .catch(console.error);
  }, []);

  const nextProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const prevProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
  };

  return (
    <div className="relationshipwithministers-page-container">
      <main className="relationshipwithministers-main-content">
        <div className="relationshipwithministers-content-wrapper">
          <div className="relationshipwithministers-content-area">
            <h2 className="relationshipwithministers-content-title">RELATIONSHIP WITH LINE MINISTRIES</h2>
            
            <div className="relationshipwithministers-main-image">
              <img src="../ministers.jpg" alt="AIC meeting with ministers" />
            </div>
            
            <div className="relationshipwithministers-content-text">
              <p>
                Ministries serve as the central hubs where public policy is formulated, debated, and
                refined to address the diverse needs and priorities of the populace. These institutions play
                a critical role in shaping the direction of governmental action and ensuring that policies
                are implemented effectively. Within this context, the AIC law delineates a clear and
                stringent process for the involvement of the Agency for Infrastructure and Construction
                (AIC) in projects that involve public assets.
              </p>
              <p>
                According to the law, it requires a formal decision by the Council of Ministers to authorize
                the AIC to undertake any such project. This requirement underscores the importance of 
                high-level oversight and accountability, ensuring that public resources are managed 
                responsibly and transparently.
              </p>
              <p>
                AIC functions as a strategic tool and operational agent available to line ministries. This
                relationship enables ministries to leverage AIC's specialized expertise, resources, and
                capabilities to bring their projects to fruition. When a ministry identifies a project that
                aligns with its strategic vision and objectives, it can choose to employ AIC as a means to
                implement and actualize the initiative.
              </p>
              <p>
                This collaborative mechanism facilitates the translation of policy into tangible outcomes, 
                allowing ministries to execute projects that are integral to their mission and contribute to 
                the broader goals of public service and national development.
              </p>
            </div>
          </div>
          
          <aside className="relationshipwithministers-sidebar">
            <nav className="relationshipwithministers-sidebar-nav">
              <h3 className="relationshipwithministers-sidebar-title">QUICK LINKS</h3>
              <ul className="relationshipwithministers-sidebar-buttons">
                {sidebarButtons.map((button) => (
                  <li key={button.path}>
                    <Link 
                      to={button.path}
                      className={`relationshipwithministers-sidebar-button ${activeButton === button.path ? 'active' : ''}`}
                      onClick={() => setActiveButton(button.path)}
                    >
                      {button.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            
            <div className="relationshipwithministers-project-section">
              <h3 className="relationshipwithministers-project-title">OUR PROJECTS</h3>
              {projects.length > 0 ? (
                <div className="relationshipwithministers-project-carousel">
                  <div className="relationshipwithministers-project-card">
                    {projects[currentProjectIndex].mainImage?.asset?.url && (
                      <img
                        src={projects[currentProjectIndex].mainImage.asset.url}
                        alt={projects[currentProjectIndex].mainImage.alt || projects[currentProjectIndex].title}
                        className="relationshipwithministers-project-image"
                      />
                    )}
                    <div className="relationshipwithministers-project-card-content">
                      <h4>{projects[currentProjectIndex].title}</h4>
                      <p>{projects[currentProjectIndex].excerpt}</p>
                      <Link to={`/projects/${projects[currentProjectIndex].slug.current}`} className="relationshipwithministers-read-more">
                        View Project
                      </Link>
                    </div>
                  </div>
                  <div className="relationshipwithministers-project-navigation">
                    <ChevronLeft className="relationshipwithministers-nav-arrow" onClick={prevProject} />
                    <ChevronRight className="relationshipwithministers-nav-arrow" onClick={nextProject} />
                  </div>
                </div>
              ) : (
                <p>Loading projects...</p>
              )}
            </div>
          </aside>
        </div>
      </main>
    </div>
  );
};

export default AICRelationshipPage;