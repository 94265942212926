// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="Footer-wrapper">
      <div className="Footer__investment-banner">
        <h2 className="Footer__banner-title">YOUR INVESTMENT IS SUPPORTED BY AIC!</h2>
      
        <button className="Footer__invest-button">INVEST NOW!</button>
      </div>
      <footer className="Footer">
        <div className="Footer__side-image Footer__side-image--left"></div>
        <div className="Footer__side-image Footer__side-image--right"></div>
        <div className="Footer__container">
          <div className="Footer__content">
            {/* Logo and Description */}
            <div className="Footer__section">
              <img src={`${process.env.PUBLIC_URL}/AIClogo2.png`} alt="AIC Logo" className="Footer__logo" /> {/* Add logo here */}
              <p className="Footer__company-description">
                Albanian Investment Corporation (AIC) was established by Law no. 71, of October 17, 2019 of the Parliament of Albania. AIC has one purpose alone: make growth happen in Albania.
              </p>
              <div className="Footer__social-icons">
                <span className="Footer__icon">in</span>
                <span className="Footer__icon">ig</span>
                <span className="Footer__icon">fb</span>
              </div>
            </div>

            {/* Useful Links */}
<div className="Footer__section">
  <h3 className="Footer__section-title">USEFUL LINKS</h3>
  <ul className="Footer__link-list">
    {[
      { name: 'About Us', },
      { name: 'Open Calls',  },
      { name: 'Projects', },
      { name: 'Team', },
      { name: 'News',  },
      { name: 'Events'  },
      { name: 'Career',  },
      { name: 'Contact',   }
    ].map((item) => (
      <li key={item.name} className="Footer__link-item">
        <a href={item.link} style={{ color: 'white' }} target="_blank" rel="noopener noreferrer">
          {item.name}
        </a>
      </li>
    ))}
  </ul>
</div>



            {/* Get in Touch */}
            <div className="Footer__section">
              <h3 className="Footer__section-title">GET IN TOUCH</h3>
              <ul className="Footer__contact-list">
                <li className="Footer__contact-item">
                  <span className="Footer__icon">🕒</span> Mon-Fri: 09:00-16:00
                </li>
                <li className="Footer__contact-item">
                  <span className="Footer__icon">✉</span> info@aicorporation.al
                </li>
                <li className="Footer__contact-item">
                  <span className="Footer__icon">📍</span> St. Ibrahim Rugova 5, Sky Tower 7/1, Tirana, Albania
                </li>
              </ul>
            </div>
          </div>

          {/* Copyright */}
          <div className="Footer__copyright">
            <p>&copy; 2024 Albanian Investment Corporation.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
