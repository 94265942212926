import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './WhyChooseAIC.css';

const WhyChooseAIC = () => {
  const location = useLocation();

  const aicLinks = [
    { name: 'OUR VISION & MISSION', path: '/about-us/vision-and-mission' },
    { name: 'WHY CHOOSE AIC', path: '/about-us/why-choose-aic' },
    { name: 'BY LAWS & REGULATIONS', path: '/about-us/laws-and-regulations' },
  ];

  return (
    <div className="centered-container">
      <div className="whychooseaic-container">
        <div className="whychooseaic-content">
          <div className="whychooseaic-header">
            <div className="red-block"></div>
            <h3 className="whychooseaic-subtitle">WHY CHOOSE AIC</h3>
          </div>
          <h2 className="whychooseaic-title">ALBANIAN INVESTMENT CORPORATION</h2>
          
          <section className="whychooseaic-section">
            <h3 className="section-title">About Albania</h3>
            <div className="section-content">
              <p>Albania is experiencing robust economic growth, driven by strategic investments in infrastructure, energy, and tourism. The government's pro-business policies and favorable tax regime have made it an attractive destination for foreign investors.</p>
              <p>With a young and skilled workforce, Albania offers significant opportunities in sectors such as agriculture, technology, and renewable energy. The country's strategic location further enhances its appeal, providing easy access to European markets and facilitating international trade.</p>
              <p>Albania captivates with its stunning natural beauty, featuring pristine beaches along the Adriatic and Ionian Seas, majestic mountains, and a rich tapestry of historical and cultural landmarks.</p>
              <p>It's rapidly emerging as a hub for entrepreneurs, offering a supportive business environment, attractive investment opportunities, and a young, dynamic workforce eager to innovate.</p>
            </div>
          </section>

          <section className="whychooseaic-section">
            <h3 className="section-title">Public-Private Partnerships and Infrastructure Development</h3>
            <div className="section-content">
              <p>AIC encourages public-private partnerships, leveraging modern technology and sharing investment risks. It focuses on transforming state properties and buildings, through collaborative efforts with public institutions and private partners.</p>
              <p>This approach acts as a strategic facilitator between the government, private sector, and other stakeholders, driving economic development aligned with state policies and objectives.</p>
              <h4>Key Aspects of AIC's Role:</h4>
              <ul>
                <li><strong>Legal Authority:</strong> AIC has the necessary legal framework and authority to identify state properties with development potential, plan their development, construction, or reconstruction, and oversee these projects.</li>
                <li><strong>Unique Role:</strong> This new practice for the country involves potential risks, such as coordination issues with state institutions, delays in property consolidation and transfer procedures, and challenges in managing complex logistics.</li>
              </ul>
              <h4>AIC's Strategies:</h4>
              <ol>
                <li><strong>Enhanced Coordination with State Institutions:</strong> As a state-owned company, AIC can establish strong communication channels with relevant state institutions to ensure compliance and smooth coordination throughout the project's lifecycle.</li>
                <li><strong>Simplification of Asset Consolidation and Transfer:</strong> To efficiently consolidate and transfer assets, minimizing delays and bureaucratic obstacles, AIC will enter into agreements with state institutions owning the state assets to be developed.</li>
              </ol>
              <p>This role positions AIC as a central player in real estate and infrastructure development in Albania.</p>
            </div>
          </section>

          <section className="whychooseaic-section">
            <h3 className="section-title">Incentives and Financial Benefits</h3>
            <div className="section-content">
              <p>AIC offers a range of tax reductions, subsidies, and streamlined administrative procedures designed to facilitate a smooth and efficient investment process. These incentives are structured to enhance the attractiveness of Albania as a business destination, reducing operational costs and increasing profitability for investors.</p>
            </div>
          </section>
        </div>
        
        <aside className="whychooseaic-sidebar">
          <nav className="whychooseaic-nav">
            <h3 className="sidebar-title">QUICK LINKS</h3>
            <ul className="whychooseaic-links">
              {aicLinks.map((link) => (
                <li key={link.path}>
                  <Link 
                    to={link.path}
                    className={`whychooseaic-link ${location.pathname === link.path ? 'active' : ''}`}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
      </div>
    </div>
  );
};

export default WhyChooseAIC;