import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import sanityClient from '../../sanity';
import './ExploreOurSection.css';

const ExploreOurProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "projectsPost"]{
          _id,
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          metaTitle
        }`
      )
      .then((data) => {
        console.log('Fetched projects:', data);
        if (data.length === 0) {
          setError('No projects found.');
        } else {
          setProjects(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('Sanity fetch error:', err);
        setError('Failed to load projects.');
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading projects...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="explore-our-section-wrapper">
      <img src="/Asset 2.png" alt="" className="background-image left" />
      <img src="/Asset 2.png" alt="" className="background-image right" />
      <div className="explore-our-section">
      <div className="exploreoursections-header">
  <h2 className="exploreoursections-subtitle">OUR PROJECTS</h2>
  <h1 className="exploreoursections-main-title">DISCOVER OUR SECTORS</h1>
</div>

        <div className="sector-grid">
          {projects.map((project) => (
            <Link to={`/projects/${project.slug.current}`} key={project._id} className="sector-card-link">
              <div className="sector-card">
                <div className="sector-content">
                  <img 
                    src={project.mainImage?.asset?.url} 
                    alt={project.mainImage?.alt || project.title} 
                    className="sector-image" 
                  />
                  <div className="sector-title-overlay"></div>
                  <div className="sector-overlay">
                    <div className="overlay-content">
                      <h3>{project.metaTitle || project.title}</h3>
                    </div>
                    <div className="hover-image-container">
                      <img src="/Asset 1.png" alt="" className="hover-image" />
                    </div>
                  </div>
                  <div className="bottom-left-image-container">
                    <img src="/Asset 1.png" alt="" className="bottom-left-image" />
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      
        <div className="view-projects-btn-container">
          <Link to="/projects" className="view-projects-btn">VIEW PROJECTS</Link>
        </div>
      </div>
    </div>
  );
};

export default ExploreOurProjects;