import React from 'react';

const ResponsiveImagePage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center text-[#e41e26]">INSTITUTIONAL STRUCTURE</h1>
      <div className="flex justify-center">
        <img
          src="../organigrama.png"
          alt="Institutional Structure"
          className="w-full max-w-4xl h-auto object-cover rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

export default ResponsiveImagePage;