import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import sanityClient from '../../sanity';
import BlockContent from '@sanity/block-content-to-react';

const TeamMemberBio = () => {
  const [teamMember, setTeamMember] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    setIsLoading(true);
    sanityClient
      .fetch(
        `*[_type == "teamMember" && slug.current == $slug][0]{
          name,
          role,
          "imageUrl": image.asset->url,
          email,
          linkedin,
          bio,
          education,
          expertise,
          languages,
          currentRole,
          previousRoles
        }`,
        { slug }
      )
      .then((data) => {
        setTeamMember(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to load team member data");
        setIsLoading(false);
      });
  }, [slug]);

  if (isLoading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-500">{error}</div>;
  if (!teamMember) return <div className="text-center py-10">No team member found</div>;

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
    <div className="max-w-8xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
      <div className="md:flex h-full">
        {/* Image Container */}
        <div className="w-full md:w-1/3 relative h-[50vh] md:h-auto">
          {/* Image */}
          <img 
            className="w-full h-full object-cover" 
            src={teamMember.imageUrl} 
            alt={teamMember.name} 
          />
          </div>
          <div className="p-8 w-full md:w-2/3">
            <div className="flex justify-between items-center mb-6">
              <Link to="/team/meet-the-staff" className="text-indigo-600 hover:text-indigo-800 flex items-center">
                <span className="mr-2" aria-hidden="true">&larr;</span>
                Back to Team
              </Link>
              <div className="flex space-x-4">
                {teamMember.linkedin && (
                  <a href={teamMember.linkedin} target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-indigo-600">
                    <span className="sr-only">LinkedIn</span>
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
                    </svg>
                  </a>
                )}
                {teamMember.email && (
                  <a href={`mailto:${teamMember.email}`} className="text-gray-400 hover:text-indigo-600">
                    <span className="sr-only">Email</span>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </a>
                )}
              </div>
            </div>
            <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{teamMember.role}</div>
            <h1 className="mt-1 text-3xl leading-tight font-bold text-gray-900">{teamMember.name}</h1>
            
            {teamMember.currentRole && (
              <div className="mt-4">
                <h2 className="text-xl font-semibold text-gray-900">Current Position</h2>
                <p className="mt-2 text-gray-700">{teamMember.currentRole}</p>
              </div>
            )}
            
            {teamMember.bio && (
              <div className="mt-6">
                <h2 className="text-xl font-semibold text-gray-900">Biography</h2>
                <div className="mt-2 text-gray-700">
                  <BlockContent blocks={teamMember.bio} />
                </div>
              </div>
            )}
            
            {teamMember.education && teamMember.education.length > 0 && (
              <div className="mt-6">
                <h2 className="text-xl font-semibold text-gray-900">Education</h2>
                <ul className="mt-2 space-y-2">
                  {teamMember.education.map((edu, index) => (
                    <li key={index} className="text-gray-700">
                      <span className="font-medium">{edu.degree}</span> - {edu.school}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            
            {teamMember.expertise && teamMember.expertise.length > 0 && (
              <div className="mt-6">
                <h2 className="text-xl font-semibold text-gray-900">Areas of Expertise</h2>
                <div className="mt-2 flex flex-wrap gap-2">
                  {teamMember.expertise.map((item, index) => (
                    <span key={index} className="bg-indigo-100 text-indigo-800 text-sm font-medium px-3 py-1 rounded-full">
                      {item}
                    </span>
                  ))}
                </div>
              </div>
            )}
            
            {teamMember.languages && teamMember.languages.length > 0 && (
              <div className="mt-6">
                <h2 className="text-xl font-semibold text-gray-900">Languages</h2>
                <p className="mt-2 text-gray-700">{teamMember.languages.join(", ")}</p>
              </div>
            )}
            
            {teamMember.previousRoles && teamMember.previousRoles.length > 0 && (
              <div className="mt-6">
                <h2 className="text-xl font-semibold text-gray-900">Previous Roles</h2>
                <ul className="mt-2 list-disc list-inside space-y-1">
                  {teamMember.previousRoles.map((role, index) => (
                    <li key={index} className="text-gray-700">{role}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberBio;