import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('sending');

    try {
      const result = await emailjs.sendForm(
        'service_3mr613b',
        'template_a4bp2k7',
        form.current,
        'p-K0sXb449NWZX-83'
      );

      console.log(result.text);
      setSubmitStatus('success');
      form.current.reset();
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus('error');
    }

    setTimeout(() => setSubmitStatus(null), 3000);
  };

  const getButtonText = () => {
    switch(submitStatus) {
      case 'sending':
        return 'Sending...';
      case 'success':
        return 'Sent Successfully';
      case 'error':
        return 'Failed to Send';
      default:
        return 'Send Message';
    }
  };

  return (
    <div className="max-w-7xl mx-auto my-10 font-sans bg-gray-100 p-8 rounded-2xl">
      <div className="flex flex-col lg:flex-row gap-16">
        <div className="lg:w-1/3">
          <h2 className="text-lg text-gray-700 mb-1">CONTACT US</h2>
          <h3 className="text-3xl text-red-600 mb-6">GET IN TOUCH</h3>
          <div className="flex items-center mb-4 text-gray-600">
            <span className="mr-3 text-xl">🕒</span>
            <span>Mon-Fri: 09:00-16:00</span>
          </div>
          <div className="flex items-center mb-4 text-gray-600">
            <span className="mr-3 text-xl">✉️</span>
            <span>info@aicorporation.al</span>
          </div>
          <div className="flex items-center mb-4 text-gray-600">
            <span className="mr-3 text-xl">📍</span>
            <span>St. Ibrahim Rugova 5, Sky Tower 9/1, Tirana, Albania</span>
          </div>
        </div>
        <div className="lg:w-2/3">
          <form ref={form} onSubmit={handleSubmit} className="space-y-6">
            <div className="flex flex-col sm:flex-row gap-4">
              <input
                type="text"
                name="firstName"
                placeholder="First name*"
                required
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last name*"
                required
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
            </div>
            <div className="flex flex-col sm:flex-row gap-4">
              <input
                type="email"
                name="email"
                placeholder="Your E-mail*"
                required
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Your Phone Number"
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
            </div>
            <div className="flex flex-col sm:flex-row gap-4">
              <input
                type="text"
                name="companyName"
                placeholder="Company Name"
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
              <input
                type="text"
                name="address"
                placeholder="Address*"
                required
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
            </div>
            <textarea
              name="message"
              placeholder="Your message*"
              required
              className="w-full px-5 py-4 rounded-3xl border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300 h-40 resize-y"
            ></textarea>
            <button 
              type="submit" 
              className={`px-8 py-4 rounded-full text-white text-lg font-semibold shadow-md transition duration-300 ${
                submitStatus === 'sending' ? 'bg-orange-500 animate-pulse' :
                submitStatus === 'success' ? 'bg-green-500' :
                submitStatus === 'error' ? 'bg-red-500' :
                'bg-red-600 hover:bg-red-700'
              }`}
              disabled={submitStatus === 'sending'}
            >
              {getButtonText()}
            </button>
          </form>
        </div>
      </div>
      <div className="mt-12 w-full h-[500px] rounded-2xl overflow-hidden shadow-lg">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3109.5028029306114!2d19.818901416227434!3d41.32812867926939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350310350d8fc6f%3A0x76a4bf9b7cc6e259!2sSky%20Tower%20Tirana!5e0!3m2!1sen!2s!4v1693446255454!5m2!1sen!2s"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map Location"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactForm;