import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import sanityClient from '../../sanity';
import './ProjectsShowcase.css';  // Keep your CSS and design intact

const ProjectsShowcase = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);  // Track loading state
  const [error, setError] = useState(null);  // Track errors

  const pinBase64 = '/pin.png';  // Reference from the public folder
  const leftImageBase64 = '/Asset 3.png';  // Reference from the public folder
  const rightImageBase64 = '/Asset 3.png';  // Reference from the public folder

  useEffect(() => {
    // Fetch all project posts from Sanity
    sanityClient
      .fetch(
        `*[_type == "projectsPost"]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          description
        }`
      )
      .then((data) => {
        setProjects(data);
        setLoading(false);  // Set loading to false when done
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to load project posts.");
        setLoading(false);  // Set loading to false in case of error
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="projects-showcase-container">
      <div className="projects-showcase-header">
        <img src={leftImageBase64} alt="Left" className="projects-showcase-side-image projects-showcase-left-image" />
        <div className="projects-showcase-header-content">
          <h1>PROJECTS</h1>
          <p>
            AIC aims to spur economic development through engaging in complex investment projects. It was established to act as an asset manager and project developer for under-utilized public sector assets in order to promote their monetization via private capital mobilization, thereby creating a positive impact on the domestic economy.
          </p>
        </div>
        <img src={rightImageBase64} alt="Right" className="projects-showcase-side-image projects-showcase-right-image" />
      </div>

      <div className="projects-showcase-card-container">
        {projects.slice(0, 4).map((project) => (
          <div key={project.slug.current} className="projects-showcase-card">
            <div className="projects-showcase-icon">
              <img src={pinBase64} alt="Pin" />
            </div>
            <div className="projects-showcase-image">
              {project.mainImage?.asset?.url && (
                <img src={project.mainImage.asset.url} alt={project.mainImage.alt || project.title} />
              )}
            </div>
            <div className="projects-showcase-card-content">
              <h3 className="projects-showcase-title">
                <Link to={`/projects/${project.slug.current}`} className="project-link">
                  {project.title}
                </Link>
              </h3>
              <p>{project.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsShowcase;
