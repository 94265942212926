import React from 'react';
import './PinAboutUs.css';

const PinAboutUs = () => {
  const leftImageBase64 = 'Asset 3.png'; // Replace with your left image base64 string
  const rightImageBase64 = 'Asset 3.png'; // Replace with your right image base64 string

  return (
    <div className="PinAboutUs">
      <img src={leftImageBase64} alt="Left side view" className="PinAboutUs-side-image PinAboutUs-left-image" />
      <div className="PinAboutUs-content">
        <h2>OUR VISION</h2>
        <h1>INNOVATIVE INVESTING ALBANIAN INVESTMENT CORPORATION</h1>
        <p>
          AIC envisions promoting sustainable economic growth by supporting investment projects across various sectors,
          including scientific and technological parks, fair industries, tourism, and real estate industry. The corporation aims to
          transform ideas into funded projects and optimize state assets for greater efficiency.
        </p>
        <p>
          By leveraging both public and private capital, AIC seeks to develop and finance projects that contribute to economic
          development and adhere to state policies. The corporation is committed to aiding the government in reviewing and
          structuring projects to ensure their success and alignment with national goals.
        </p>
      </div>
      <img src={rightImageBase64} alt="Right side view" className="PinAboutUs-side-image PinAboutUs-right-image" />
    </div>
  );
};

export default PinAboutUs;
