import React, { useState } from 'react';

const JobApplicationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    experience: '',
    coverLetter: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Add your form submission logic here
  };

  return (
    <div className="max-w-3xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
      <h1 className="text-4xl font-bold mb-6 text-red-600 text-center">WORK WITH US!</h1>
      <div className="mb-8 text-gray-700 text-center">
        <p className="mb-4">
          AIC is in continuous search for talent in various fields, including engineers, architects, urban planners, financiers, lawyers, project managers, and technical experts, with the aim of realizing the Corporation's objectives.
        </p>
        <p className="mb-4">
          We invite you to send a resume and a letter of interest to <strong className="text-red-600">hr@aicorporation.al</strong>.
        </p>
        <p className="mb-4">
          If your experience aligns with AIC's needs, we will contact you to further advance your professional engagement within AIC. Employment at AIC is conducted according to the provisions of the Labor Code.
        </p>
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
            required
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
            required
          />
        </div>
        <div>
          <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
            required
          />
        </div>
        <div>
          <label htmlFor="experience" className="block text-gray-700 font-bold mb-2">Relevant Experience</label>
          <textarea
            id="experience"
            name="experience"
            value={formData.experience}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
            rows="4"
            required
          ></textarea>
        </div>
        <div>
          <label htmlFor="coverLetter" className="block text-gray-700 font-bold mb-2">Cover Letter</label>
          <textarea
            id="coverLetter"
            name="coverLetter"
            value={formData.coverLetter}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
            rows="6"
            required
          ></textarea>
        </div>
        <div>
          <label className="block text-gray-700 font-bold mb-2">Upload Resume</label>
          <input type="file" className="w-full" />
        </div>
        <div>
          <label className="block text-gray-700 font-bold mb-2">Upload Cover Letter</label>
          <input type="file" className="w-full" />
        </div>
        <button type="submit" className="w-full mt-6 bg-red-600 text-white font-bold py-2 px-4 rounded-md hover:bg-red-700 transition duration-300">
          Submit Application
        </button>
      </form>
    </div>
  );
};

export default JobApplicationForm;