import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './MissionSection.css';

const MissionSection = () => {
  const location = useLocation();

  const missionLinks = [
    { name: 'OUR VISION & MISSION', path: '/about-us/vision-and-mission' },
    { name: 'WHY CHOOSE AIC', path: '/about-us/why-choose-aic' },
    { name: 'BY LAWS & REGULATIONS', path: '/about-us/laws-and-regulations' },
  ];

  return (
    <div className="centered-container">
      <div className="mission-container">
        <div className="mission-text">
          <div className="mission-header">
            <div className="red-block"></div>
            <h3 className="mission-subtitle">OUR MISSION</h3>
          </div>
          <h2 className="mission-title">INNOVATIVE INVESTING ALBANIAN INVESTMENT CORPORATION</h2>
          <p className="mission-description">
            The Albanian Investment Corporation (AIC) is dedicated to driving economic and social development
            through strategic national and regional investment projects. These efforts align with state policies
            and aim to effectively utilize state assets, mobilize public and private capital, and convert innovative
            ideas into viable, impactful projects.
          </p>
          <p className="mission-description">
            AIC plays a crucial role in assisting the government with project review and structuring, ensuring
            that investments are well-planned and executed to maximize benefits.
          </p>
        </div>
        <aside className="mission-sidebar">
          <nav className="mission-nav">
            <h3 className="sidebar-title">QUICK LINKS</h3>
            <ul className="mission-links">
              {missionLinks.map((link) => (
                <li key={link.path}>
                  <Link 
                    to={link.path}
                    className={`mission-link ${location.pathname === link.path ? 'active' : ''}`}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
      </div>
    </div>
  );
};

export default MissionSection;