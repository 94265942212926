import React, { useState, useEffect } from 'react';
import sanityClient from '../../sanity'; // Ensure this path is correct

const ByLawPDFDisplayPage = () => {
  const [pdfs, setPdfs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`
        *[_type == "multiplePdfDocument"]{
          pdfFiles[]{
            "customTitle": customTitle,
            "fileName": pdfFile.asset->originalFilename,
            "fileUrl": pdfFile.asset->url
          }
        }
      `)
      .then((result) => {
        const allPdfs = result.flatMap(doc => doc.pdfFiles);
        setPdfs(allPdfs);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching PDFs:", err);
        setError("Failed to load PDF documents.");
        setLoading(false);
      });
  }, []);

  if (loading) return <div className="flex items-center justify-center h-64"><div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-red-600"></div></div>;
  if (error) return <div className="text-center py-8 text-xl text-red-600">{error}</div>;

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">By Law & Regulation Documents</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {pdfs.length > 0 ? (
            pdfs.map((pdf, index) => (
              <div key={index} className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 overflow-hidden">
                <div className="p-6 border-l-4 border-[#e41e26] h-full flex flex-col">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4 flex-grow">{pdf.customTitle}</h2>
                  <div className="text-sm text-gray-500 mb-4 truncate">{pdf.fileName}</div>
                  <a
                    href={pdf.fileUrl}
                    download={pdf.fileName}
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-[#e41e26] hover:bg-[#c51a21] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#e41e26] transition-colors duration-300"
                  >
                    View PDF
                  </a>
                </div>
              </div>
            ))
          ) : (
            <div className="col-span-full text-center text-gray-800 italic bg-white p-8 rounded-xl shadow-sm">
              No PDF documents are currently available. Please check back later.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ByLawPDFDisplayPage;